
.modal-dialog {
    max-width: 700px;
};

@media (max-width: 368px) {
    /* .modal-dialog {
    
    
    } */
};

