.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html {
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: rgba(227, 26, 109, 0.7) rgba(16, 194, 201, 0.1);
}